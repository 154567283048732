import { lazy } from 'react';

export default {
	// PremiumLanding: lazy(() => import('./landing-premium')),
	CoursePage: lazy(() => import('./course')),
	HackathonLanding: lazy(() => import('./hackathon')),
	// PaidCoursesPage: lazy(() => import('./paid')),
	HomePage: lazy(() => import('./home')),
	Auth: lazy(() => import('./auth')),

	Page404: lazy(() => import('./404')),
	ErrorPage: lazy(() => import('./error')),
	Checkout: lazy(() => import('./checkout')),
	PaymentFailed: lazy(() => import('./payment-failed')),
	ForgtoPassword: lazy(() => import('./forgot-password')),
	CoursePurchased: lazy(() => import('./course-purchased')),
	PaymentSuccess: lazy(() => import('./payment-success')),
	BuyMeCoffeeRedirect: lazy(() => import('./buy-me-coffee')),
	AlreadyPurchase: lazy(() => import('./already-purchased')),
	AllCourse: lazy(() => import('./course-page')),
};
