import { validateCoupon } from "./coupons";
import { validateReferralCode } from "./referrals";
import {
  getCheckout,
  getCourseInfo,
  getAllCourseInfo,
  getHybridCourse,
  getCourseDetails,
  enrollUserInCourse,
  getEnrollmentStatus,
  getAddOnCoursesDetails,
  getCourseCurriculum,
  getCohortsCourses,
  getCohortDetails,
} from "./course";
import { createFormResponse, getFormResponse } from "./formResponse";
import { getAllBundles, getBundleDetails, getBundleCurriculum } from "./bundle";
import { postFeedback } from "./feedbacks";
import {
  loginUsser,
  createUser,
  googlesignin,
  generateOtp,
  verifyOtp,
  resendOtp,
  resetPasswordRequest,
} from "./users";
import {
  coursePaymentV1,
  payV1,
  coursePaymentV2,
  payV2,
  coffeePayV2,
  coursePaymentV3,
  payV3,
  validatePaymentV3,
} from "./payment";
const apis = {
  //Courses
  getCheckout,
  getCourseInfo,
  getAllCourseInfo,
  getHybridCourse,
  getCourseDetails,
  getAddOnCoursesDetails,
  getCourseCurriculum,
  getCohortsCourses,
  getCohortDetails,
  //Bundles
  getAllBundles,
  getBundleDetails,
  getBundleCurriculum,
  enrollUserInCourse,
  getEnrollmentStatus,
  //Payments
  payV1,
  payV2,
  payV3,
  coffeePayV2,
  coursePaymentV1,
  coursePaymentV2,
  coursePaymentV3,
  validatePaymentV3,
  //Responses
  createFormResponse,
  getFormResponse,
  // Users
  loginUsser,
  createUser,
  generateOtp,
  verifyOtp,
  resendOtp,
  googlesignin,
  resetPasswordRequest,
  //Coupons
  validateCoupon,
  //Feedbacks
  postFeedback,
  //Referrals,
  validateReferralCode,
};

export default apis;
