import client from "./_client";

const endpointV1 = "/api/v1/courses";

export const getCheckout = (details) =>
  client.post(endpointV1 + `/checkout`, details);

export const getCourseInfo = ({ title, courseId }) =>
  client.get(
    endpointV1 + `?${title ? `title=${title}` : `courseId=${courseId}`}`
  );

export const getAllCourseInfo = (page, limit) =>
  client.get(endpointV1 + `/all-courses?page=${page}&limit=${limit}`);

export const getHybridCourse = ({ title }) =>
  client.get(endpointV1 + `/hybrid?title=${title}`);

export const getCourseDetails = ({ title }) =>
  client.get(endpointV1 + `?title=${title}`);

export const enrollUserInCourse = (details) =>
  client.post(endpointV1 + "/enroll-user", details);

export const getEnrollmentStatus = (id) =>
  client.get(endpointV1 + `/enroll-status?id=${id}`);

export const getAddOnCoursesDetails = (courseId) =>
  client.get(endpointV1 + `/add-on?courseId=${courseId}`);

export const getCourseCurriculum = ({ title }) =>
  client.get(endpointV1 + `/curriculum?title=${title}`);

export const getCohortsCourses = (page, limit, courseMode) =>
  client.get(
    endpointV1 +
      `/all-cohorts?page=${page}&limit=${limit}&courseMode=${courseMode}`
  );

export const getCohortDetails = ({ title }) =>
  client.get(endpointV1 + `/cohort?title=${title}`);
