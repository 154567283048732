import CryptoJS from 'crypto-js';
import { createTransform } from 'redux-persist';

const secretKey = 'CipherSchoolsIsEdTechPlatform';

const encrypt = (inboundState, key) => {
	const encryptedState = CryptoJS.AES.encrypt(JSON.stringify(inboundState), secretKey).toString();
	return encryptedState;
};

const decrypt = (outboundState, key) => {
	const bytes = CryptoJS.AES.decrypt(outboundState, secretKey);
	const decryptedState = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
	return decryptedState;
};

const encryptTransform = createTransform(
	(inboundState, key) => encrypt(inboundState, key),
	(outboundState, key) => decrypt(outboundState, key)
);

export default encryptTransform;
