import types from '../types';

const initialState = {
	feedbackModal: false,
};

const ModalReducer = (state = initialState, actions = {}) => {
	switch (actions.type) {
		case types.modalTypes.TOGGLE_FEEDBACK_MODAL:
			return {
				...state,
				feedbackModal: !state.feedbackModal,
			};
		default:
			return state;
	}
};

export default ModalReducer;
