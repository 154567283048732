import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';

import encryptTransform from 'utils/encryptTransformer';
import reducerStore from 'store/store';

// Redux Persist config
const persistConfig = {
	key: 'root',
	storage, // LocalStorage or SessionStorage, choose as per your needs
	transforms: [encryptTransform],
	whitelist: ['user', 'course'],
};

// Create the persisted reducer
const persistedReducer = persistReducer(persistConfig, reducerStore);

// Create the store with middleware and Redux devtools extension
const store = configureStore({
	reducer: persistedReducer,
	devTools: process.env.NODE_ENV !== 'production',
	middleware: getDefaultMiddleware =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
			},
		}),
});

// Persistor for persistence handling
const persistor = persistStore(store);

// Export the store and persistor for Next.js integration
export { store, persistor };
