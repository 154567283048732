import types from "../types";

const initialState = {
  _id: null,
  email: "",
  name: "",
  mobileNo: "",
  profile: "",
  username: "",
};

const UserDetailsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.userDetailsTypes.FETCH_USER_DETAILS:
      return {
        // ...state,
        _id: action.payload._id,
        email: action.payload?.email,
        name: action.payload?.name,
        mobileNo: action.payload?.mobileNo,
        profile: action.payload.profile,
        username: action.payload.username,
      };

    // case types.userDetailsTypes.RESET_USER_DETAILS:
    // 	return {
    // 		...initialState,
    // 	};

    default:
      return state;
  }
};

export default UserDetailsReducer;
