import { create } from "apisauce";

const apiClient = create({
  // Development
  // baseURL: "http://localhost:3002",
  // Development
  // production on cs.cipherschools
  baseURL: "https://www.cs.cipherschools.com",

  withCredentials: true,
  timeout: 20000,
});

const get = apiClient.get;

apiClient.get = async (url, params, axiosConfig) => {
  const response = await get(url, params, axiosConfig);
  return response;
};

export default apiClient;
