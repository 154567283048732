import { combineReducers } from 'redux';

import UserDetailsReducer from './reducers/userDetails.reducer';
import ModalReducer from './reducers/modal.reducer';
import CourseReducer from './reducers/course.reducer';

	
const allReducers = combineReducers({
	course: CourseReducer,
	modal: ModalReducer,
	user: UserDetailsReducer,
});

export default allReducers;
