import client from "./_client";

const endpointV1 = "/api/v1/payment";

const endpointV2 = "/api/v2/payment";

const endpointV3 = "/api/v3/payment";

export const coursePaymentV1 = (details) =>
  client.post(endpointV1 + "/course-pay", details);
export const payV1 = (details) => client.post(endpointV1 + "/pay", details);

export const coursePaymentV2 = (details) =>
  client.post(endpointV2 + "/course-pay", details);
export const coffeePayV2 = (details) =>
  client.post(endpointV2 + "/coffee-pay", details);
export const payV2 = (details) => client.post(endpointV2 + "/pay", details);

export const coursePaymentV3 = (details) =>
  client.post(endpointV3 + "/course-pay", details);
export const payV3 = (details) => client.post(endpointV3 + "/pay", details);

export const validatePaymentV3 = (details) =>
  client.post(endpointV3 + "/validate", details);
