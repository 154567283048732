import types from '../types';

const initialState = {
	courseDetails: null,
	courseCurriculum: null,
};

// eslint-disable-next-line default-param-last
const CourseReducer = (state = initialState, action) => {
	switch (action.type) {
		case types.courseTypes.FETCH_COURSE_DETAILS:
			return {
				...state,
				courseDetails: action.payload,
			};

		case types.courseTypes.FETCH_COURSE_CURRICULUM:
			return {
				...state,
				courseCurriculum: action.payload,
			};

		default:
			return state;
	}
};

export default CourseReducer;
