import React from "react";
import { Provider } from "react-redux";
import { createRoot } from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";

import reportWebVitals from "./tests/reportWebVitals";
import { store } from "./configStore";
import config from "configs";
import "./styles/index.scss";
import App from "./app";

const root = document.getElementById("root");
const rootContainer = createRoot(root);

const id = config.GOOGLE_CLIENT_ID_OFFICIAL;

if (root.hasChildNodes()) {
  rootContainer.hydrate(
    <React.StrictMode>
      <GoogleOAuthProvider clientId={id}>
        <Provider store={store}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </Provider>
      </GoogleOAuthProvider>
    </React.StrictMode>
  );
} else {
  rootContainer.render(
    <React.StrictMode>
      <GoogleOAuthProvider clientId={id}>
        <Provider store={store}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </Provider>
      </GoogleOAuthProvider>
    </React.StrictMode>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
