import { Outlet, useLocation } from "react-router-dom";
import React, { Suspense } from "react";
import PropTypes from "prop-types";

import styles from "./style.module.scss";
import Loader from "components/loaders";
import Navbar from "components/navbar";

function Layout(props) {
  const dashboardLinks = [
    { name: "Self-Paced Courses", id: "programs" },
    { name: "Online-live Courses", id: "live-courses" },
    { name: "Classroom Courses", id: "offline-courses" },
    { name: "Submit Query", id: "modal", type: "modal" },
  ];

  const premiumLinks = [
    { name: "Takeaways", id: "takeaways" },
    { name: "Success Stories", id: "success-stories" },
    { name: "Curriculum", id: "curriculum" },
    { name: "Opportunities", id: "opportunities" },
    { name: "FAQs", id: "faqs" },
  ];

  const location = useLocation();
  const { type, place } = props;

  return (
    <Suspense fallback={<Loader.PageLoader />}>
      {type === "dynamic" ? (
        <Navbar
          links={premiumLinks}
          btnClassName="white"
          showBanner={location.pathname.includes("/courses")}
        />
      ) : type === "dashboard" ? (
        <Navbar links={dashboardLinks} btnClassName="white" place={place} />
      ) : type === "no-back" ? (
        <Navbar />
      ) : null}
      <main
        className={`${styles.main} ${
          location.pathname.includes("/courses") ? styles.paid : ""
        }`}
      >
        <Suspense fallback={<Loader.PageLoader />}>
          <Outlet />
        </Suspense>
      </main>
    </Suspense>
  );
}

Layout.propTypes = {
  type: PropTypes.string,
  place: PropTypes.string,
};

export default Layout;
