import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Suspense } from "react";

import HackathonLayout from "layouts/HackathonLayout";
import Loader from "components/loaders";
import Layout from "layouts";
import Pages from "pages";

function App() {
  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <Suspense fallback={<Loader.PageLoader />}>
        <Routes>
          <Route element={<Layout type="dynamic" />}>
            <Route path="/courses/:title" element={<Pages.CoursePage />} />
          </Route>
          <Route element={<Layout type="dashboard" place="All Course" />}>
            <Route path="/courses" element={<Pages.AllCourse />} />
          </Route>
          <Route element={<Layout type="dashboard" />}>
            <Route path="/" element={<Pages.HomePage />} />
          </Route>
          <Route element={<Layout type="no-back" />}>
            <Route path="/error" element={<Pages.ErrorPage />} />
            <Route path="/already-paid" element={<Pages.CoursePurchased />} />
            <Route path="/auth/:authtype" element={<Pages.Auth />} />
            <Route path="/checkout/:course" element={<Pages.Checkout />} />
            <Route path="/payment-failed" element={<Pages.PaymentFailed />} />
            <Route path="/forgot-password" element={<Pages.ForgtoPassword />} />
            <Route path="/payment-success" element={<Pages.PaymentSuccess />} />
            <Route
              path="/course-purchased"
              element={<Pages.CoursePurchased />}
            />
            <Route
              path="/buy-me-coffee"
              element={<Pages.BuyMeCoffeeRedirect />}
            />
            <Route
              path="/already-purchase"
              element={<Pages.AlreadyPurchase />}
            />
          </Route>
          <Route element={<HackathonLayout />}>
            <Route path="/hackathon" element={<Pages.HackathonLanding />} />
          </Route>
          <Route element={<Layout type="no-back" />}>
            <Route path="*" element={<Pages.Page404 />} />
          </Route>
        </Routes>
      </Suspense>
    </>
  );
}

export default App;
