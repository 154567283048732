import client from "./_client";

const endpointV1 = "/api/v1/users";

export const loginUsser = (details) =>
  client.post(endpointV1 + "/login", details);

export const createUser = (details) =>
  client.post(endpointV1 + "/signup", details);

export const generateOtp = (details) =>
  client.post(endpointV1 + "/otp", details);

export const verifyOtp = (details) =>
  client.put(endpointV1 + "/verify-otp", details);

export const resendOtp = (email) =>
  client.put(endpointV1 + `/resend-otp`, { email });

export const googlesignin = (details) =>
  client.post(endpointV1 + "/google-signin", details);

export const resetPasswordRequest = (email) =>
  client.post(`${endpointV1}/resetpassword?email=${email}`);
