import client from "./_client";

const endpointV1 = "/api/v1/bundles";

export const getAllBundles = (page, limit) =>
  client.get(endpointV1 + `/all-bundles?page=${page}&limit=${limit}`);

export const getBundleDetails = ({ title }) =>
  client.get(endpointV1 + `?title=${title}`);

export const getBundleCurriculum = ({ title }) =>
  client.get(endpointV1 + `/curriculum?title=${title}`);
