import * as userDetailsTypes from './userDetails.types';
import * as courseTypes from './course.types';
import * as modalTypes from './modal.types';

const types = {
	courseTypes,
	modalTypes,
	userDetailsTypes,
};

export default types;
