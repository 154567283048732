import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Select from "react-select";

import { Icon } from "@iconify/react";
import PropTypes from "prop-types";
import "./feedback.scss";
import api from "apis";

function FeedbackModal(props) {
  const [reqText, setReqText] = useState({
    name: "",
    email: "",
    contact: "",
    feedback: "",
    type: "",
  });
  const [isFeedbackSent, setIsFeedbackSent] = useState(false);
  const [loading, setLoading] = useState(false);

  const FeedbackList = [
    {
      value: "Payment",
    },
    {
      value: "Course Access",
    },
    {
      value: "Coupon Issue",
    },
    {
      value: "Course Curriculum",
    },
    {
      value: "Bug Reports",
    },
    {
      value: "Others",
    },
  ];

  const defaultProps = {
    options: FeedbackList,
    getOptionLabel: (option) => option.value,
  };

  const isEmpty = () => {
    if (
      reqText.name === "" ||
      reqText.email === "" ||
      reqText.contact === "" ||
      reqText.feedback === "" ||
      reqText.type === ""
    ) {
      return true;
    } else {
      return false;
    }
  };

  const submitFeedback = async () => {
    const data = {
      feedbackType: reqText?.type,
      userEntity: {
        name: reqText?.name,
        email: reqText?.email,
        contact: reqText?.contact,
      },
      type: "premium-access-feedback",
      message: reqText?.feedback,
    };

    if (!isEmpty) {
      toast.error("Fill the required fileld");
    } else {
      setLoading(true);
      const response = await api.postFeedback(data);
      if (response?.status === 201) {
        toast.success("Query submitted successfully!");
        setIsFeedbackSent(true);
        setLoading(false);
        return;
      }
      toast.error(response?.data?.data?.error);
      setLoading(false);
    }
  };
  return (
    <>
      {!isFeedbackSent ? (
        <section className="feedback">
          <div className="sf-textfield-row">
            <Select
              {...defaultProps}
              options={FeedbackList}
              className="sf-textfield-dropdown"
              value={
                FeedbackList.find((option) => option.value === reqText.type) ||
                null
              }
              onChange={(selectedOption) => {
                setReqText((prev) => ({
                  ...prev,
                  type: selectedOption.value || "",
                }));
              }}
              autoHighlight
              blurOnSelect
              disableClearable
            />
          </div>
          <div className="sf-textfield-row">
            <input
              placeholder="Enter Your Name"
              value={reqText.name}
              className="sf-textfield"
              onChange={(e) =>
                setReqText((prev) => ({
                  ...prev,
                  name: e.target.value,
                }))
              }
            />
          </div>

          <div className="sf-textfield-row">
            <input
              placeholder="Email Address"
              value={reqText.email}
              className="sf-textfield"
              onChange={(e) =>
                setReqText((prev) => ({ ...prev, email: e.target.value }))
              }
            />
          </div>

          <div className="sf-textfield-row">
            <input
              placeholder="Contact Number"
              value={reqText.contact}
              className="sf-textfield"
              onChange={(e) =>
                setReqText((prev) => ({ ...prev, contact: e.target.value }))
              }
            />
          </div>

          <div className="sf-textfield-msg">
            <textarea
              className="sf-msg-cont"
              value={reqText.feedback}
              onChange={(e) =>
                setReqText((prev) => ({
                  ...prev,
                  feedback: e.target.value,
                }))
              }
              placeholder="Enter your message here..."
            />
          </div>
          <div className="sf-btns-cont">
            <button
              disabled={isEmpty()}
              className={isEmpty() ? "sf-msg-btn" : "sf-msg-btn sf-btn-active"}
              onClick={submitFeedback}
            >
              {loading ? "Submitting..." : "Submit"}
            </button>
          </div>
        </section>
      ) : (
        <section className="feedback-sent">
          <p>
            Your query has been submitted successfully! Our team will get back
            to you soon.
          </p>

          <div className="whatsapp-div">
            <Icon icon="logos:whatsapp-icon" />
            <p>Join our WhatsApp query group for faster responses.</p>
          </div>

          <button
            className="whatsapp-btn"
            onClick={() =>
              window.open(
                "https://chat.whatsapp.com/DjgbzPvBu5W4PdTudwW59H",
                "_blank"
              )
            }
          >
            Join WhatsApp Group
          </button>
        </section>
      )}
    </>
  );
}

FeedbackModal.propTypes = {
  setShowFeedback: PropTypes.state,
};

export default FeedbackModal;
