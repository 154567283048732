import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { Icon } from "@iconify/react";
import React, { useEffect } from "react";

import "./modal.scss";

const Modal = (props) => {
  const {
    children,
    heading,
    className,
    placement,
    showModal,
    setShowModal,
    footer,
    block,
  } = props;

  useEffect(() => {
    if (showModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [showModal]);

  return (
    <>
      {ReactDOM.createPortal(
        showModal ? (
          <div
            className="background"
            style={
              placement === "top"
                ? { paddingTop: "8vh" }
                : { alignItems: "center" }
            }
            onClick={() => (block ? {} : setShowModal(false))}
          >
            <div
              className={`modalContainer ${className}`}
              onClick={(e) => e.stopPropagation()}
            >
              <div className="header">
                <div className={`heading`}>
                  <h2>{heading}</h2>
                </div>
                <button
                  className={`cross-left`}
                  onClick={() => setShowModal(false)}
                >
                  <Icon
                    icon="bitcoin-icons:cross-filled"
                    id={"cross-left-icon"}
                  />
                </button>
              </div>
              {children}
            </div>
            {footer ? <div className={`footer`}>{footer}</div> : null}
          </div>
        ) : null,
        document.getElementById("modal-root")
      )}
    </>
  );
};

Modal.propTypes = {
  children: PropTypes.node,
  heading: PropTypes.string,
  className: PropTypes.string,
  placement: PropTypes.string,
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  footer: PropTypes.node,
  block: PropTypes.bool,
};

export default Modal;
